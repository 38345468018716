import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import axios from 'axios';
import './App.css';

function App() {

  const { code, mode } = useParams();

  const getUrl = () => {
    let baseUrl = '';
    if (mode === 'd') {
      baseUrl = 'https://api-dev.goodlii.com/';
    } else if (mode === 'u') {
      baseUrl = 'https://api-uat.goodlii.com/';
    } else {
      baseUrl = 'https://partner-api.goodlii.com/';
    }
    axios.get(`${baseUrl}shop/qr-code/${code}`)
      .then(res => {
        if (res.data.data) {
          if (window.navigator.userAgent.indexOf("Android") !== -1) {
            window.location.replace(res.data.data);
          } else if (window.navigator.userAgent.indexOf("iPhone") !== -1) {
            window.location.replace(res.data.data);
          } else {
            window.location.replace("https://goodlii.com/");
          }
        } else {
          console.log(res);
        }
      }).catch((error) => {
        console.log(error);
      })
  };

  useEffect(() => {
    getUrl();
  }, []);

  return (
    <div className="spinner-wrapper full-screen">
      <img
        src="/orange-loader.gif"
        alt="Loading"
        width="150px"
        height="150px"
      />
    </div>
  );
}

export default App;
